/* Basic styles for the navigation menu */
.navbar {
  font-family: 'Roboto', sans-serif; /* Add this for a modern font. Remember to import from Google Fonts */
  display: flex;
  justify-content: center; /* Updated for better spacing */
  align-items: center;
  background-color: #212426;
  padding: 10px 20px;
  border-bottom: 2px solid #efccae;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* subtle shadow for depth */
}

.toggle-button {
  background: none;
  border: none;
  font-size: 28px; /* slightly bigger icon */
  color: #fff;
  cursor: pointer;
  display: none;
  transition: color 0.3s; /* smooth color transition */
}

.toggle-button:hover {
  color: #efccae; /* Color change on hover */
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px; /* Spacing between items */
}

.nav-menu li {
  padding: 10px 15px;
  transition: background-color 0.3s; /* smooth background transition */
}

.nav-menu li:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.nav-menu a {
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  font-weight: 500; /* Slightly bold */
  transition: color 0.3s; /* smooth color transition */
}

.nav-menu a:hover {
  color: #efccae; /* Color change on hover */
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .toggle-button {
      display: block;
  }

  .nav-menu {
      display: none;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #212426;
      z-index: 1;
      border-radius: 8px; /* Rounded corners */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow for elevated look */
  }

  .nav-menu.open {
      display: flex;
  }

  .nav-menu.open ul {
      flex-direction: column;
      gap: 0; /* No gap for mobile */
  }

  .nav-menu.open a {
      padding: 15px 0;
  }
}




/* Basic styles for the navigation menu
.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #212426;
    padding: 10px 20px;
    border-bottom: 1px solid #efccae;
  }
  
  .toggle-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    display: none;
  }
  
  .nav-menu {
    display: flex;
  }
  
  .nav-menu ul {
    list-style-type: none;
    padding: 0;
    display: flex;
  }
  
  .nav-menu li {
    margin: 20px;
  }
  
  .nav-menu a {
    color: #fff;
    text-decoration: none;
    padding: 10px;
  }
  
  /* Media query for responsiveness */
 /*
  @media screen and (max-width: 768px) {
    .toggle-button {
      display: block;
    }
  
    .nav-menu {
      display: none;
      flex-direction: column;
      text-align: center;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      background-color: #212426;
      z-index: 1;
    }
  
    .nav-menu.open {
      display: flex;
    }
  
    .nav-menu.open ul {
      flex-direction: column;
    }
  
    .nav-menu.open a {
      padding: 10px 0;
    }
  }
   */