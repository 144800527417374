.submit-tool {
  background: linear-gradient(135deg, #2a2e31, #1a1c1d);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  padding: 30px;
  border-radius: 12px;
  width: 100%;
  max-width: 720px;
  margin: 3rem auto;
}

.add-tool h3 {
  margin-bottom: 2.5rem;
  text-align: center;
  color: #fff; /* make it white to match our background */
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #fff;
}

input[type="text"],
input[type="number"],
select,
textarea,
input[type="file"] {
  width: 100%;
  padding: 10px;
  border: 2px solid #555;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  background-color: #2a2e31;
  outline: none;
  transition: border-color 0.3s;
}

input[type="text"]:focus,
input[type="number"]:focus,
select:focus,
textarea:focus,
input[type="file"]:focus {
  border-color: #007bff;
}

/* Add some styles to enhance the select arrow */
select {
  appearance: none;
  padding-right: 30px;
  background-image: url('data:image/svg+xml;utf8,<svg fill="white" viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
}

textarea {
  resize: vertical;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #0056b3;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 8px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


.toast-message {
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  z-index: 1001;
  animation: fadeIn 0.5s, fadeOut 0.5s 4.5s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to   { opacity: 0; }
}





/* .submit-tool {
  background-color: #333333;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 720px;
  margin: 2rem auto;

}

.add-tool h3{
  margin-bottom: 2.5rem;
  text-align: center;
}

h1 {
  text-align: center;
  color: #333;
}

.container-devider {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #fff;
}

input[type="text"],
input[type="number"],
select,
textarea {
  width: 100%;
  padding: 9px;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  background-color: #424242;
}

select {
  appearance: none;
}

textarea {
  resize: vertical;
}

.btn {
  background-color: #007bff;
  color: #fff;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #0056b3;
} */