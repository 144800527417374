*{
    padding: 0px;
    margin: 0px;
}
p, h3, button {
    color: white;
}

.tool-link{
    cursor: pointer;
}

.tool-link:hover{
    color: rgb(72 72 188);
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 15px auto;
    gap: 20px;
}
.tool-details{
    border-radius: 10px;
    overflow: hidden;
    background-color: rgb(37, 37, 37);
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    width: 100%;
    border: 1px solid #4848bc47;
    margin: 15px auto;
}
.badge{
    position: absolute;
    background-color: rgb(246, 246, 246);
    padding: 5px 7px;
    border-radius: 5px;
    top: 15px;
    right: 15px;
    color: black;
    font-size: 14px;
}
.tool-details-image  {
    height: 450px;
    width: 1280px;
    overflow: hidden;
}
.tool-details-image>img{
    height: 450px;
    width: 1280px;
    object-fit: cover;
    transition: .5s ease;
}
.image > img:hover {
    transform: scale(1.2);
}
.header {
    padding: 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}
.header > span{
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 5px;
}
.header > span > svg{
    font-size: 22px;
    color: white;
}
h3 > svg {
    color: rgb(72, 72, 188);;
    font-size: 20px;
}

.tagline{
    color: #ebc8ab;
}
.stars {
    padding: 0px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
}
.stars > div > svg {
    color: yellow !important;
    font-size: 18px;
}
.stars> p{
    color: rgba(255, 255, 255, 0.497);
    font-size: 12px;
}
.description{
    padding: 0px 15px;
}
.price{
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.price > button {
    background-color: rgba(77, 77, 162, 0.315);
    padding: 5px 7px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
}
.price > span {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    align-items: flex-start;
}
.price > span > svg {
    font-size: 25px;
    color: white;
}
.price > span > p {
    font-size: 18px;
}
.tags {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
}
.btns{
    padding: 15px 15px 15px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
}
.btns > button {
    width: 100%;
    padding: 7px 10px 0px;
    font-size: 20px;
    background-color: rgb(72, 72, 188);
    border: none;
    border-radius: 5px;
    outline: none;
}
.btns > button:nth-of-type(2){
    background-color: transparent;
    border:1px solid rgb(72, 72, 188);;
}