.news-item{
    width: 100%;
    padding: 12px 16px 16px;
    border: 1px solid rgb(189, 189, 189);
    border-radius: 8px;
}

.inner-container-top{
    display: flex;
}

.inner-container-top .news-title{
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    font-weight: 600;
}
.inner-container-top .news-title:hover{
    color: rgb(14, 165, 233);
    cursor: pointer;
}


.inner-container-top .news-link{
    cursor: pointer;
    color: rgb(14, 165, 233);
    font-size: 0.7em;
    font-weight: 600;
    line-height: 1.7rem;
    margin-left: 10px;
}

.inner-container-news{
    display: flex;
    flex-direction: row;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 12px;
}

.news-item .published-date{
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    color: rgb(158, 158, 158);
    white-space: pre;
    font-weight: 500;
}

.news-item .category{
    max-width: 100%;
    min-width: 130px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 32px;
    white-space: nowrap;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    cursor: default;
    outline: 0px;
    text-decoration: none;
    padding: 8px 8px 8px 8px;
    vertical-align: middle;
    box-sizing: border-box;
    border: 1px solid rgb(97, 97, 97);
    color: rgb(255, 255, 255);
    background-color: rgb(55, 65, 81);
    border-radius: 8px;
}